import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "aktKomponen";
const modelPath = "/aktivitas/keterampilan-klinis/";

class AktivitasKeterampilan extends ModelBase {
  constructor() {
    let model = {
      id: null,
      preceptor: null,
      tanggal: null,
      lokasi: null,
      keterampilan: null,
      foto: null,
      catatan: "",
      virtual: false,
      kompetensi: "",
      jenis: null
    };
    let requiredFields = ["tanggal", "preceptor", "keterampilan"];
    let nonRequiredFiels = ["foto", "lokasi", "catatan", "kompetensi", "jenis", "virtual"];
    super(modelName, model, requiredFields, nonRequiredFiels, modelPath);
  }

  getLoadData(data) {
    let tdata = super.getLoadData(data);
    tdata.keterampilan = tdata.keterampilan.id;
    return tdata;
  }
}

export default AktivitasKeterampilan;
