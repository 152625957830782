<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <b-message
        v-if="showInformation()"
        title="Info"
        type="is-info"
        has-icon
        aria-close-label="Close message"
      >
        <strong>Menu Keterampilan PSPD</strong> dikhususkan untuk mencatat
        aktivitas katerampilan yang memiliki target pencapaian. Untuk mencatat
        aktivitas lain seperti mengikuti kuliah umum, jurnal reading, seminar,
        webinar dan ilmiah sebagai peserta (non presenter) dan aktivitas lain
        yang tidak diberikan target pencapaian dapat diinputkan pada
        <strong>Menu Kegiatan</strong>.
      </b-message>
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light is-uppercase">
            {{ headerText }} AKTIVITAS KETERAMPILAN
          </p>
        </header>
        <div class="card-content events-card">
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>

          <b-field
            label="Tanggal"
            class="tgl-field"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.tanggal }"
            :message="errorMap.tanggal"
          >
            <date-picker
              v-model="aktKomponen.tanggal"
              @input="validateInput('tanggal')"
            ></date-picker>
          </b-field>
          <b-checkbox v-model="aktKomponen.virtual" @input="setEdited(true)"
            >Virtual</b-checkbox
          >
          <b-field
            v-if="!aktKomponen.virtual"
            label="Kompetensi"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.kompetensi }"
            :message="errorMap.kompetensi"
          >
            <b-select
              v-model="aktKomponen.kompetensi"
              placeholder="Kompetensi"
              required
              expanded
              @input="validateInput('kompetensi')"
            >
              <option
                v-for="kompetensi in kompetensiOptions"
                :value="kompetensi"
                :key="kompetensi"
              >
                {{ kompetensi }}
              </option>
            </b-select>
          </b-field>
          <b-field
            v-if="!aktKomponen.virtual"
            label="Jenis Keterampilan"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.jenis }"
            :message="errorMap.jenis"
          >
            <generic-select
              v-model="aktKomponen.jenis"
              apiPath="/aktivitas/keterampilan/jenis/?jenis=offline_tanpa_pasien"
              @input="validateInput('jenis')"
            ></generic-select>
          </b-field>

          <b-field
            label="Lokasi"
            class="ac-field"
            custom-class="is-small notrequired"
            :type="{ 'is-danger': errorMap.lokasi }"
            :message="errorMap.lokasi"
          >
            <generic-autocomplete
              placeholder="Cari sebelum buat baru"
              apiPath="/perawatan/lokasi/"
              v-model="aktKomponen.lokasi"
              @input="validateInput('lokasi')"
              @typing="onLokasiACTyping"
              :required="false"
            ></generic-autocomplete>
            <div class="control">
              <router-link :to="lokasiAddTo">
                <b-button class="is-primary">
                  <b-icon icon="plus" />
                </b-button>
              </router-link>
            </div>
            <div :v-show="false">
              <!-- diperlukan agar menjadi addons, terkait permasalahan di method
              b-field.hasAddons dimana kalkulasi jumlah node di slot ini, baru button-nya
              yang terhitung. dan tidak di-watch. kemungkinan terkait race-condition.
              -->
            </div>
          </b-field>

          <b-field
            label="Preceptor"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.preceptor }"
            :message="errorMap.preceptor"
          >
            <generic-autocomplete
              apiPath="/anggota/preceptor/"
              v-model="aktKomponen.preceptor"
              @input="validateInput('preceptor')"
            ></generic-autocomplete>
          </b-field>

          <b-field
            label="Keterampilan"
            class="ac-field"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.keterampilan }"
            :message="errorMap.keterampilan"
          >
            <generic-autocomplete
              placeholder="Cari sebelum buat baru"
              apiPath="/penyakit/keterampilan/"
              v-model="aktKomponen.keterampilan"
              @input="validateInput('keterampilan')"
              @typing="onKeterampilanACTyping"
            ></generic-autocomplete>

            <div class="control">
              <router-link :to="keterampilanAddTo">
                <b-button class="is-primary">
                  <b-icon icon="plus" />
                </b-button>
              </router-link>
            </div>
            <div :v-show="false">
              <!-- diperlukan agar menjadi addons, terkait permasalahan di method
              b-field.hasAddons dimana kalkulasi jumlah node di slot ini, baru button-nya
              yang terhitung. dan tidak di-watch. kemungkinan terkait race-condition.
              -->
            </div>
          </b-field>

          <b-field
            label="Catatan"
            custom-class="is-small notrequired"
            :type="{ 'is-danger': errorMap.catatan }"
            :message="errorMap.catatan"
          >
            <b-input
              v-model="aktKomponen.catatan"
              type="textarea"
              @input="validateInput('catatan')"
            ></b-input>
          </b-field>

          <b-field
            class="file"
            :type="{ 'is-danger': errorMap.foto }"
            :message="errorMap.foto"
          >
            <b-upload
              v-model="fotoMdl"
              @input="resize"
              accept=".jpg, .jpeg, .png"
            >
              <a class="button is-primary">
                <b-icon icon="upload"></b-icon>
                <span>Upload Foto</span>
              </a>
            </b-upload>
            <span class="file-name" v-if="fotoMdl">{{ fotoMdl.name }}</span>
          </b-field>

          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>

          <div class="buttons">
            <b-button
              class="is-primary has-text-weight-semibold"
              @click.stop="cancel"
              >Batal</b-button
            >
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject(!fotoMdl)"
              >Simpan</b-button
            >
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import AktivitasKeterampilan from "../models/cuAktKeterampilan.js";
import UploadPhoto from "@/apps/core/modules/uploadPhoto.js";

export default {
  name: "AktKeterampilanCreateUpdate",
  props: ["headerText"],
  mixins: [createUpdateMixin],
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
  },
  data() {
    this.aktivitasKetMdl = new AktivitasKeterampilan();
    this.objectMdl = this.aktivitasKetMdl; // alias yg digunakan di cuMixin
    let obv = this.aktivitasKetMdl.getObservables();
    this.kompetensiOptions = ["Observasi", "Operator dengan Supervisi"];
    obv.keterampilanInput = "";
    obv.lokasiInput = "";
    obv.foto = null;
    obv.fotoMdl = null;
    this.uploadPhoto = new UploadPhoto("aktivitasketerampilan");
    return obv;
  },
  computed: {
    ...mapState("aktivitas", {
      aktKomponenState: "aktKomponen",
      aktLokasiState: "aktLokasi",
    }),
    keterampilanAddTo() {
      const aktKomponen = this.getaktKomponen();
      return {
        name: "keterampilan-add",
        params: { aktKomponen: aktKomponen },
      };
    },
    lokasiAddTo() {
      const aktKomponen = this.getaktKomponen();
      return {
        name: "lokasi-add",
        params: { aktLokasi: aktKomponen },
      };
    },
  },
  methods: {
    ...mapActions("aktivitas", ["setAktKomponen", "setAktLokasi"]),
    onKeterampilanACTyping(value) {
      this.keterampilanInput = value;
    },
    onLokasiACTyping(value) {
      this.lokasiInput = value;
    },
    showInformation() {
      const today = new Date();
      const end_date = new Date("2020-07-13");
      return today < end_date;
    },
    getaktKomponen() {
      const aktKomponen = JSON.parse(JSON.stringify(this.aktKomponen));
      if (!this.isCreate) {
        aktKomponen["edited"] = this.aktivitasKetMdl.getEdited();
      }
      return aktKomponen;
    },
    fetchData() {
      if (this.aktKomponenState || this.aktLokasiState) {
        let aktState = this.aktKomponenState
          ? this.aktKomponenState
          : this.aktLokasiState;
        this.aktKomponen = JSON.parse(JSON.stringify(aktState));
        if (this.isCreate) {
          this.aktKomponen.aktivitas = this.$route.params.aktivitasId;
          this.aktKomponen.foto = null;
          this.uploadPhoto.requestAWS();
        } else {
          this.setEdited(this.aktKomponen["edited"]);
        }

        for (const field of this.aktivitasKetMdl.getRequiredFields()) {
          if (this.aktKomponen[field]) {
            this.validateInput(field);
          }
        }
        this.setAktKomponen(null);
        this.setAktLokasi(null);
      } else {
        this.aktKomponen.aktivitas = this.$route.params.aktivitasId;
        this.aktKomponen.foto = null;
        this.uploadPhoto.requestAWS();
      }
    },
    resize(foto) {
      this.uploadPhoto.resize(foto, (foto, url) => {
        this.foto = foto;
        this.aktKomponen.foto = url;
        this.validateInput("foto");
      });
    },
    upload() {
      this.uploadPhoto.upload(this.foto, this.saveObject, () => {
        this.aktKomponen.foto = null;
        this.foto = null;
        this.fotoMdl = null;
      });
    },
    onSaved(respObj) {
      this.$router.replace({
        name: "aktivitas-keterampilan-detail",
        params: { id: respObj.id },
      });
    },
    saveObject(commit) {
      this.objectMdl.setCommit(commit);
      if (commit) {
        this.objectMdl.save(this.saveContext, this.onSaved);
      } else {
        this.objectMdl.save(this.saveContext, this.upload);
      }
    },
  },
  watch: {
    aktKomponen: {
      // aktivitas berubah, update edited
      handler(newValue, oldValue) {
        if (!this.aktivitasKetMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 769px), print {
  .field-label {
    flex-grow: 0;
    text-align: left;
  }
}

::v-deep .ac-field {
  button {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  p.help {
    display: none;
  }
}
</style>
